
function scrollToTop() {
	if($(window).scrollTop() > 100){
		$('.scrollToTop').addClass('active');
	}


	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$('.scrollToTop').addClass('active');
		} else {
			$('.scrollToTop').removeClass('active');
		}
	});
//
	$('.scrollToTop').click(function(){
		$('html, body').animate({scrollTop : 0},800);
		return false;
	});
}





function openWin2($url) {
	myWin = open("http://www.facebook.com/sharer.php?u=" + $url, "displayWindow", "width=520,height=300,left=350,top=170,status=no,toolbar=no,menubar=no");
}
function loacation() {
	location.reload();
}
function getURLVar(key) {
	var value = [];

	var query = String(document.location).split('?');

	if (query[1]) {
		var part = query[1].split('&');

		for (i = 0; i < part.length; i++) {
			var data = part[i].split('=');

			if (data[0] && data[1]) {
				value[data[0]] = data[1];
			}
		}

		if (value[key]) {
			return value[key];
		} else {
			return '';
		}
	}
}
function onlyDigit(){
	$("input.qty").keypress(function(e) { //if the letter is not digit then display error and don't type anything
		if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
			return false
		}
	});
	$("input.digit").keypress(function(e) { //if the letter is not digit then display error and don't type anything
		if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
			return false
		}
	});
}
function customSelectInit(){
	console.log('customSelect_Init');
	$(".custom-select select").each(function() {
		if(!$(this).hasClass('is-customized')) {
			$(this).addClass('is-customized');
			var sb = new SelectBox({
				selectbox: $(this),
				customScrollbar: true,
				height: 300,
				changeCallback: function() {
					//alert("I was changed!");
				}
			});
			sb.sync();
		};
	});
}




function fixTopLine() {
	if ($('body').hasClass('product-page')) {
		console.log('fixAnchorsMenuLine');

		setTimeout(function () {
			var anchorsMenuPosition = $('.product__bottom_block_menu').position().top;

				$(".product__bottom_block_menu").removeClass("fixed");
				if ($(this).scrollTop() > anchorsMenuPosition) {
					$(".product__bottom_block_menu").addClass("fixed");
				} else {
					$(".product__bottom_block_menu").removeClass("fixed");
				};


				$(window).scroll(function () {
					if ($(this).scrollTop() > anchorsMenuPosition) {
						$(".product__bottom_block_menu").addClass("fixed");
					} else {
						$(".product__bottom_block_menu").removeClass("fixed");
					};
				});

		}, 1000);

	}else{
		/*	console.log('fixTopLineNotProductPage');
            var headerHeight = $('.header__top_block').outerHeight();
            if ($(window).width() >= 1024) {

                $(".header__bottom_block").removeClass("fixed");
                if ($(this).scrollTop() > headerHeight) {
                    $(".header__bottom_block").addClass("fixed");
                } else {
                    $(".header__bottom_block").removeClass("fixed");
                };


                $(window).scroll(function () {
                    if ($(this).scrollTop() > headerHeight) {
                        $(".header__bottom_block").addClass("fixed");
                    } else {
                        $(".header__bottom_block").removeClass("fixed");
                    };
                });
            }
    */
    /*<------NEW Fixed Header------*/

				var headerOffset = $('.header-site').offset().top;
				var headerHeight = $('.header-site').height();

				$(window).scroll(function (e) {
					if (!($('.header-site').css('display') == 'none')){
						var scrolled = $(this).scrollTop();

						if (scrolled > headerOffset) {

							$('body').css('padding-top', `${headerHeight}px`);
							$('.header-site').addClass('fixed')

						} else if (scrolled <= headerOffset) {

							$('body').css('padding-top', `0px`);
							$('.header-site').removeClass('fixed')
						}
					}
					else {
						$('body').css('padding-top', `0px`);
					}
				});

	}
}

function mainCatalogMenu(){
	$('.header_menu_button').click(function(){
		$('.header_menu_content').toggleClass('active');
	});
	$('.header_menu_content').mouseleave(function(){
		$('.header_menu_content').removeClass('active');
	});
}
function subMenuHeight(){
	setTimeout(function () {
		$height = $('#header_menu').height() + 4;
		$('#header_menu ul>li>.sub_menu_container').css('height',$height);
	}, 600);
}


function mobileHeaderMenu(){
	$('#cat_menu #catalog_li > .text').click(function(){
		$('#cat_menu #catalog_li #catalog_next_level_button_first').click();
	});


	$('#mobile_menu_header .mobile_menu_button').click(function(){
		$('body').addClass('overflow_hidden');
		$(this).addClass('hidden');
		$('.mobile_menu_button_close').removeClass('hidden');
		$('#mobile_menu_content').addClass('active');
	});


	$('.mobile_menu_button_close').click(function(){
		$('body').removeClass('overflow_hidden');
		$(this).addClass('hidden');
		$('.mobile_menu_button').removeClass('hidden');
		$('#mobile_menu_content').removeClass('active');
	})

	$('.next_level_button_first').click(function(){
		$('.mobile_menu_button_close').addClass('hidden');
		$('.mobile_menu_button_back').removeClass('hidden');
	});

	$('.mobile_menu_button_back').click(function(){
		$(this).addClass('hidden');
		$('.mobile_menu_button_close').removeClass('hidden');
		$('#cat_menu .sub_menu_container').removeClass('active');
		$('#cat_menu .next_level_button').removeClass('active');
	})

	/************/

	$('.next_level_button').click(function(){
		$(this).toggleClass('active');
		$(this).next().toggleClass('active');

	});

	$('#cat_menu button.next_level_button_first').click(function () {
		$('#mobile_menu_header').addClass('menu_first_level_open');
	})


}

function openSearch(){
	$('.open_search').click(function(){
		$(this).toggleClass('active');
		$('.search').toggleClass('active');
	});

	$('html').click(function() {
		if ($('.open_search.active').length){
			$('.open_search').removeClass('active');
			$('.search').removeClass('active');
		}
	});
	$('.open_search').click(function(event){
		event.stopPropagation();
	});
	$('.search').click(function(event){
		event.stopPropagation();
	});
}

function openMobileSearch(){
	$('.mobile_open_search').on('click', function(e){
		e.preventDefault();
		$(this).toggleClass('active');
		$('#mobile_menu_header .search').toggleClass('active');
	});
	$('html').click(function() {
		if ($('.mobile_open_search.active').length){
			$('.mobile_open_search').removeClass('active');
			$('.search').removeClass('active');
		}
	});
}


function mainSlideshow() {
	if ($('#main_slideshow').length) {
		setTimeout(function () {
			$('#main_slideshow').slick({
				infinite: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				asNavFor: '.custom_navigation',
				arrows: false,
				dots: false,
			});

			$('.custom_navigation').slick({
				infinite: false,
				autoplay: false,
				autoplaySpeed: 4000,
				vertical:false,
				slidesToShow: 3,
				slidesToScroll: 1,
				asNavFor: '#main_slideshow',
				arrows: false,
				dots: false,
				focusOnSelect: true,
				variableWidth: true,
				centerMode: true,
			});
		}, 200);
	}
};


function blogSlider() {
	if ($('.blog_slider').length) {
		$('.blog_slider').slick({
			dots: false,
			autoplay: false,
			infinite: true,
			arrows: true,
			prevArrow: '<span class="ani icon icon-arrow_left"></span>',
			nextArrow: '<span class="ani icon icon-arrow_right"></span>',
			speed: 300,
			slidesToShow: 4,
			slidesToScroll:1,
			appendArrows: $('.box_blog .box_heading .arrows_container'),
			responsive: [{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: '30px',
				}
			},{
				breakpoint: 768, settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: '30px',
					arrows: false,
					dots: true
				}}
			]
		});
	}
}


function productsSlider() {
	if ($('.products_slider').length) {

		$('.products_slider').each( function() {
			$( this ).slick( {
				dots: false,
				autoplay: true,
				infinite: true,
				arrows: true,
				prevArrow: '<span class="ani icon icon-arrow_left"></span>',
				nextArrow: '<span class="ani icon icon-arrow_right"></span>',
				speed: 300,
				slidesToShow: 4,
				slidesToScroll:1,
				appendArrows: $(this).parents('.box_products').find('.box_heading .arrows_container'),

				responsive: [{
					breakpoint: 1600,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
					}
				},{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				}, {
					breakpoint: 768, settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
						arrows: false
					}}
				]
			} );
		} );
	}
}

function collapseAboutCompany(){

	if ($('.box_about_company').length) {
		$('.box_about_company .readmore a').click(function(){
			$(this).toggleClass('active');
			$('.box_about_company .box_content').toggleClass('active');
		});
	}
}

function collapseCatDesc(){
	if ($('.cat_description').length) {
		$('.cat_description .readmore a').click(function(){
			$(this).toggleClass('active');
			$('.cat_description .cat_description_content').toggleClass('active');
		});
	}
}


function sendNewsLetterForm(){
	$('#newsletter_submit').click(function() {
		$.ajax({
			url: 'index.php?route=sendforms/send',
			type: 'post',
			data: $('#newsletter_form').serialize(),
			dataType: 'json',

			success: function(json) {

				$('#newsletter_form input').removeClass('has_error');
				$('#newsletter_form .text-danger').remove();


				if (json['error_email']) {
					$('#newsletter_form input').addClass('has_error');
					$('#newsletter_form .input_container').append('<div class="text-danger">' + json['error_email'] + '</div>');
					$('#newsletter_form input').focus();
				}

				if (json['success']) {
					$('#modal_container_success .content .content_title').html(json['success_title']);
					$('#modal_container_success .content .content_text').html(json['success_newsletter']);
					$('#modal_container_success').addClass('active');

					$('#newsletter_form .reset_input').val('')
					$('#newsletter_form .reset_input').removeClass('has_error');
					$('.newsletter_form_container .text-danger').remove();
				}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	});
}


function mobileFooterMenu(){
	$('.footer__top_block .col .col_title').click(function(){

		if($(this).parents('.col').hasClass('active')){
			$(this).parents('.col').removeClass('active');
		}else{
			$('.footer__top_block .col').removeClass('active');
			$(this).parents('.col').addClass('active');
		}

	});
}

function equalHeightCategoriesMobile(){
	if($(document).width() < 768){
		console.log('equalHeightCategoriesMobile');
		if($('.box_home_categories').length){
			$height = $('.categories_grid .cell-1').height();
			$('.categories_grid .cell-5 a').css('max-height', $height);
			$('.categories_grid .cell-6 a').css('max-height', $height)
			$('.categories_grid .cell-7 a').css('max-height', $height)
		}

	}
}


function addViewFilterMegaFilter() {
	if($('.mfilter-box').length) {

		console.log('addViewFilterMegaFilter');

		$('.active_filters > label').remove();
		$('.mfilter-input-active').each(function (index, elem) {
			if (!$(elem).closest('.mfilter-filter-item').hasClass('mfilter-categories')) {
				$(elem).find('label').clone().prependTo('.active_filters');
			}
		});

		var filters_count = $('.active_filters label').length;
		if (filters_count > 0) {
			$('.filters_button .counter').text(filters_count);
			$('.active_filters').addClass('active')
			$('.mfilter-box').addClass('active');
		} else {
			$('.filters_button .counter').text('0');
			$('.active_filters').removeClass('active')
			$('.mfilter-box').removeClass('active');
		}


		$('.mfilter-button-reset-clone').click(function () {
			$('a.mfilter-button-reset').trigger('click');
		});
	}
}


function productsGrid(){
	$("body").on("click", "#grid-view", function() {
		console.log('grid-view');
		$('.view_buttons button').removeClass('active');
		$(this).addClass('active');
		$('#content .products_row').removeClass().addClass('products_row');
		$('#content .products_row').addClass('grid-view');
		localStorage.setItem('display', 'grid-view');
	});

	// Product list-view
	$("body").on("click", "#list-view", function() {
		console.log('list-view');
		$('.view_buttons button').removeClass('active');
		$(this).addClass('active');
		$('#content .products_row').removeClass().addClass('products_row');
		$('#content .products_row').addClass('list-view');
		localStorage.setItem('display', 'list-view');
	});

	/***/

	if (localStorage.getItem('display') == 'list-view') {
		$('#list-view').trigger('click');
	}else{
		$('#grid-view').trigger('click');
	};

}

function openCloseFilter(){
	$('button.open_filter').click(function(){
		console.log('click_openfilter');
		$('body').addClass('overflow_hidden');
		$('.mfilter-free-container').addClass('open');
	});
	$('button.close_filter').click(function(){
		$('body').removeClass('overflow_hidden');
		$('.mfilter-free-container').removeClass('open');
	})
}
function resetFilter(){
	$('button.filter_reset').click(function(){
		$('a.mfilter-button-reset').trigger('click');
	});
}


function productPageSlider(){

	if ($('.product-big-image.gallery').length){
		console.log('productPageSlider Init');
		setTimeout(function(){
			$('.product-big-image.gallery').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				asNavFor: '.additional_images',
				arrows: false,
				dots: false,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							dots: true,
						}
					}
				]
			});
			$('.additional_images').slick({
				infinite: true,
				autoplay: false,
				autoplaySpeed: 4000,
				vertical: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.product-big-image',
				arrows: true,
				prevArrow: '<span></span>',
				nextArrow: '<span class="ani icon icon-arrow_right"></span>',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				responsive: [
					{
						breakpoint: 1199,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},{
						breakpoint: 991,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1
						}
					},{
						breakpoint: 600,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]

			});
		}, 200);
		$('.product-big-image.gallery a').magnificPopup({
			type:'image',
			gallery: {
				enabled: true
			}
		});

	};


	if ($('.product-big-image').length){
		$('.product-big-image a.popup').magnificPopup({
			type:'image',
			gallery: {
				enabled: false
			}
		});
	}

}



function simplecheckoutPageHacks(){
	if($('.checkout-simplecheckout').length){
		onlyDigit();
	}
}


function collapseProductDesc(){
	if ($('#block-description').length) {
		$('#block-description .readmore a').click(function(){
			$(this).toggleClass('active');
			$('#block-description .editor_content').toggleClass('active');
		});
	}
}


function closeInfoSlides(){
	console.log('closeInfoSlides INIT');
	$('.info_slide .close_button').click(function(){
		$('body').removeClass('overflow_hidden');
		$('.info_overlay').removeClass('active');
		$('.info_slide').removeClass('active');
	});
}



function showAllCharacterictics(){
	if($('.show_all_attributes_block').length){
		$('.show_all_attributes_block').click(function(){
			$(this).toggleClass('active');
			$('#block-attributes .content .attributes_item').toggleClass('active');
		});
	}
}

function productCardMenuAnchors(){
	if($('#product .product__bottom_block_menu').length){

		$('#product .product__bottom_block_menu ul li a').click(function(event){
			event.preventDefault();
			var $anchor = $(this);
			var nav = $($anchor.attr('href'));
			if(nav){
				$([document.documentElement, document.body]).animate({
					scrollTop: $(nav).offset().top - 74
				}, 2000);
			}

		});
	}
}

function buttonAnchor(){
	$('a.button_anchor').click(function(event){
		event.preventDefault();
		var $anchor = $(this);
		var nav = $($anchor.attr('href'));
		if(nav){
			$([document.documentElement, document.body]).animate({
				scrollTop: $(nav).offset().top - 74
			}, 2000);
		}
	});
}

function productCardToReviews(){
	if($('a.link_to_reviews').length){
		$('a.link_to_reviews').click(function(){
			var anchor = '#block-reviews';
			if(anchor){
				$([document.documentElement, document.body]).animate({
					scrollTop: $(anchor).offset().top - 90
				}, 2000);
			}
		});
	}
}


function collapseProductReview(){
	$('body').on('click', '.review_item .readmore a', function() {
		$(this).toggleClass('active');
		$(this).parents('.review_item').find('.review_text').toggleClass('active');
	});
}



function collapseProductInfoBlocks(){
	if($('.product__bottom_block_content .info_block').length){
		$('.product__bottom_block_content .info_block button.toggleBlock').click(function(){
			console.log('collapseInfoBlock');
			$(this).toggleClass('active');
			$(this).parents('.block_container').find('.title').toggleClass('collapsed');
			$(this).parents('.block_container').find('.content').toggleClass('collapsed');
			//with box_module
			$(this).parents('.block_container').toggleClass('collapsed')
			$(this).parents('.block_container').find('.box_content').toggleClass('collapsed');
		});
	}
}



function collapseProductAttributes(){
	if($('#block-attributes').length){
		$('#block-attributes .block_container>.content .attributes_item .title button').click(function(){
			$(this).toggleClass('active');
			$(this).parents('.attributes_item').find('.title').toggleClass('collapsed');
			$(this).parents('.attributes_item').find('.attributes_list').toggleClass('collapsed');
		});
	}
}

function productPartnersSlider() {
	if($('#block-buy_in_partners .partners_slider').length) {
		console.log('productPartnersSlider');
		$('#block-buy_in_partners .partners_slider').slick({
			dots: false,
			autoplay: false,
			infinite: true,
			arrows: true,
			prevArrow: '<span class="ani icon icon-arrow_left"></span>',
			nextArrow: '<span class="ani icon icon-arrow_right"></span>',
			speed: 300,
			slidesToShow: 5,
			slidesToScroll:1,
			appendArrows: $('#block-buy_in_partners .box_heading').find('.arrows_container'),

			responsive: [{
				breakpoint: 1366,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			}]
		} );
	}
}



function mobilePartnersSlider() {
	if ($(window).width() < 767 ) {
		if($('.mobile_partners_slider').length) {
			$('.mobile_partners_slider').slick({
				dots: true,
				autoplay: false,
				infinite: true,
				arrows: false,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
			});
		}
	}
}


/*function filterBlogArticles(){
	if($('.blog_categories_menu').length){
		$('.blog_categories_menu li a.filter').click(function(){
			$('.blog_categories_menu li a').removeClass('active');
			$(this).addClass('active');
			var catid = $(this).data('catid');
			console.log(catid);
			$('.blog_category_page_content .articles_row .article').addClass('hidden');
			$('.blog_category_page_content .articles_row .article').each(function(){
				if($(this).hasClass('article-' + catid)){
					$(this).removeClass('hidden');
				}
			});
		});
	}
}*/

/*function resetBlogArticleFilter(){
	console.log('resetBlogArticleFilter');
	$('.blog_categories_menu li a').removeClass('active');
	$('.blog_category_page_content .articles_row .article').removeClass('hidden');
}*/


function fixBlogInfoMenu() {
	if ($('.blog_categories_menu').length) {
		if ($(window).width() > 1023) {
			console.log('fixBlogInfoMenu');

			setTimeout(function () {
				var offset = $('.blog_categories_menu').offset();
				var blogMenuPosition = offset.top - 152;
				console.log(blogMenuPosition);

				$(".blog_categories_menu").removeClass("fixed");
				if ($(this).scrollTop() > blogMenuPosition) {
					$(".blog_categories_menu").addClass("fixed");

				} else {
					$(".blog_categories_menu").removeClass("fixed");
				};

				$(window).scroll(function () {
					if ($(this).scrollTop() > blogMenuPosition) {
						$(".blog_categories_menu").addClass("fixed");
					} else {
						$(".blog_categories_menu").removeClass("fixed");
					};
				});

			}, 600);
		}
	}
}


function openFeedbackForm(){
	$('.openFeedbackForm').click(function(){
		$('body').addClass('overflow_hidden')
		$('.info_overlay').addClass('active');
		$('#feedback_slide').addClass('active');
	});
}

function sendFeedbackForm(){
	$('#form-feedback #button-feedback').click(function() {
		console.log('sendFeedbackForm');
		$.ajax({
			url: 'index.php?route=sendforms/send',
			type: 'post',
			data: $('#form-feedback').serialize(),
			dataType: 'json',

			success: function(json) {
				$('#form-feedback .input_container').removeClass('has_error');
				$('#form-feedback input').removeClass('has_error');
				$('#form-feedback textarea').removeClass('has_error');
				$('#form-feedback .text-danger').remove();



				if (json['error_name']) {
					$('#form-feedback #input-name-feedback').parent().addClass('has_error');
					$('#form-feedback #input-name-feedback').addClass('has_error');
					$('#form-feedback #input-name-feedback').after('<div class="text-danger">' + json['error_name'] + '</div>');
					$('#form-feedback #input-name-feedback').focus();
				}
				if (json['error_email']) {
					$('#form-feedback #input-email-feedback').parent().addClass('has_error');
					$('#form-feedback #input-email-feedback').addClass('has_error');
					$('#form-feedback #input-email-feedback').after('<div class="text-danger">' + json['error_email'] + '</div>');
					$('#form-feedback #input-email-feedback').focus();
				}
				if (json['error_city']) {
					$('#form-feedback #input-city-feedback').parent().addClass('has_error');
					$('#form-feedback #input-city-feedback').addClass('has_error');
					$('#form-feedback #input-city-feedback').after('<div class="text-danger">' + json['error_city'] + '</div>');
					$('#form-feedback #input-city-feedback').focus();
				}

				if (json['error_message']) {
					$('#form-feedback #input-comment-feedback').parent().addClass('has_error');
					$('#form-feedback #input-comment-feedback').addClass('has_error');
					$('#form-feedback #input-comment-feedback').after('<div class="text-danger">' + json['error_message'] + '</div>');
					$('#form-feedback #input-comment-feedback').focus();
				}


				if (json['error_agree_pd']) {
					$('#form-feedback .line_agree_pd .custom_check').after('<div class="text-danger">' + json['error_agree_pd'] + '</div>');
				}

				if (json['error_agree_pp']) {
					$('#form-feedback .line_agree_pp .custom_check').after('<div class="text-danger">' + json['error_agree_pp'] + '</div>');
				}

				if (json['success']) {
					$('#form-feedback .reset_input').removeClass('error');
					$('#form-feedback .text-danger').remove();
					$('#form-feedback .input_container').removeClass('has_error');

					$('#form-feedback input[name=\'name\']').val('');
					$('#form-feedback input[name=\'email\']').val('');
					$('#form-feedback input[name=\'city\']').val('');
					$('#form-feedback textarea[name=\'message\']').val('');
					$('#form-feedback #feedback_agree_personal_data').prop('checked', false);
					$('#form-feedback #feedback_agree_privacy_policy').prop('checked', false);


					$('#modal_container_success .content .content_title').html(json['success_title']);
					$('#modal_container_success .content  .content_text').html(json['success']);

					$('#feedback_slide').removeClass('active');

					setTimeout(function () {
						$('#modal_container_success').addClass('active');
					}, 600);
				}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log('error');
				//console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	});
}

function openCheckSerialNumber(){
	if($('#check_original_slide').length){
		$('.check_serial_number').click(function(){
			$('body').addClass('overflow_hidden')
			$('.info_overlay').addClass('active');
			$('#check_original_slide').addClass('active');
		})
	}
}

function openProductRegister(){
	if($('#product_register_slide').length){
		$('.product_register').click(function(){
			$('body').addClass('overflow_hidden')
			$('.info_overlay').addClass('active');
			$('#product_register_slide').addClass('active');
		})
	}
}

function sendRegisterProductForm(){
	$('#form_product_register #button-product-register').click(function() {
		console.log('sendRegisterProductForm');
		$.ajax({
			url: 'index.php?route=sendforms/send',
			type: 'post',
			data: $('#form_product_register').serialize(),
			dataType: 'json',

			success: function(json) {
				$('#form_product_register input').removeClass('has_error');
				$('#form_product_register textarea').removeClass('has_error');
				$('#form_product_register .text-danger').remove();


				if (json['error_serial']) {
					$('#form_product_register #product_register_serial_number').addClass('has_error');
					$('#form_product_register #product_register_serial_number').after('<div class="text-danger">' + json['error_serial'] + '</div>');
					$('#form_product_register #product_register_serial_number').focus();
				}

				if (json['error_model']) {
					$('#form_product_register #product_register_model').addClass('has_error');
					$('#form_product_register #product_register_model').after('<div class="text-danger">' + json['error_model'] + '</div>');
					$('#form_product_register #product_register_model').focus();
				}

				if (json['error_fio']) {
					$('#form_product_register #product_register_fio').addClass('has_error');
					$('#form_product_register #product_register_fio').after('<div class="text-danger">' + json['error_fio'] + '</div>');
					$('#form_product_register #product_register_fio').focus();
				}

				if (json['error_telephone']) {
					$('#form_product_register #product_register_telephone').addClass('has_error');
					$('#form_product_register #product_register_telephone').after('<div class="text-danger">' + json['error_telephone'] + '</div>');
					$('#form_product_register #product_register_telephone').focus();
				}

				if (json['error_city']) {
					$('#form_product_register #product_register_city').addClass('has_error');
					$('#form_product_register #product_register_city').after('<div class="text-danger">' + json['error_city'] + '</div>');
					$('#form_product_register #product_register_city').focus();
				}


				if (json['error_agree_pd']) {
					$('#form_product_register .line_agree_pd .custom_check').after('<div class="text-danger">' + json['error_agree_pd'] + '</div>');
				}

				if (json['error_agree_pp']) {
					$('#form_product_register .line_agree_pp .custom_check').after('<div class="text-danger">' + json['error_agree_pp'] + '</div>');
				}

				if (json['success']) {
					$('#form_product_register .reset_input').removeClass('error');
					$('#form_product_register .text-danger').remove();
					$('#form_product_register .input_container').removeClass('has_error');

					$('#form_product_register input[name=\'serial\']').val('');
					$('#form_product_register input[name=\'model\']').val('');
					$('#form_product_register input[name=\'fio\']').val('');
					$('#form_product_register input[name=\'telephone\']').val('');
					$('#form_product_register input[name=\'city\']').val('');
					$('#form_product_register #product_register_agree_personal_data').prop('checked', false);
					$('#form_product_register #product_register_agree_privacy_policy').prop('checked', false);


					$('#success_register_slide .content .line_model .p_value').html(json['success_model']);
					$('#success_register_slide .content .line_serial .p_value').html(json['success_serial']);
					$('#success_register_slide .heading .title').html(json['success']);
					$('#product_register_slide').removeClass('active');

					setTimeout(function () {
						$('#success_register_slide').addClass('active');
					}, 600);
				}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log('error');
				//console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	});
}

/****************************/

function openServiceReviewForm(){
	$('.openServiceReviewForm').click(function(){
		$('body').addClass('overflow_hidden')
		$('.info_overlay').addClass('active');
		$('#add_service_review_slide').addClass('active');
	});
}

function sendServiceReviewForm(){
	$('#form-service-review #button-service-review').click(function() {
		console.log('sendServiceReviewForm');
		$.ajax({
			url: 'index.php?route=sendforms/send',
			type: 'post',
			data: $('#form-service-review').serialize(),
			dataType: 'json',

			success: function(json) {
				$('#form-service-review .input_container').removeClass('has_error');
				$('#form-service-review input').removeClass('has_error');
				$('#form-service-review textarea').removeClass('has_error');
				$('#form-service-review .text-danger').remove();

				if (json['error_rating']) {
					$('#form-service-review .reviewStars-input').addClass('error');
					$('#form-service-review .reviewStars-input').after('<div class="text-danger error_rating">'+json['error_rating']+'</div>');
				}

				if (json['error_name']) {
					$('#form-service-review #input-name-service-review').parent().addClass('has_error');
					$('#form-service-review #input-name-service-review').addClass('has_error');
					$('#form-service-review #input-name-service-review').after('<div class="text-danger">' + json['error_name'] + '</div>');
					$('#form-service-review #input-name-service-review').focus();
				}
				if (json['error_email']) {
					$('#form-service-review #input-email-service-review').parent().addClass('has_error');
					$('#form-service-review #input-email-service-review').addClass('has_error');
					$('#form-service-review #input-email-service-review').after('<div class="text-danger">' + json['error_email'] + '</div>');
					$('#form-service-review #input-email-service-review').focus();
				}
				if (json['error_telephone']) {
					$('#form-service-review #input-telephone-service-review').parent().addClass('has_error');
					$('#form-service-review #input-telephone-service-review').addClass('has_error');
					$('#form-service-review #input-telephone-service-review').after('<div class="text-danger">' + json['error_telephone'] + '</div>');
					$('#form-service-review #input-telephone-service-review').focus();
				}

				if (json['error_message']) {
					$('#form-service-review #input-service-review').parent().addClass('has_error');
					$('#form-service-review #input-service-review').addClass('has_error');
					$('#form-service-review #input-service-review').after('<div class="text-danger">' + json['error_message'] + '</div>');
					$('#form-service-review #input-service-review').focus();
				}


				if (json['error_agree_pd']) {
					$('#form-service-review .line_agree_pd .custom_check').after('<div class="text-danger">' + json['error_agree_pd'] + '</div>');
				}

				if (json['error_agree_pp']) {
					$('#form-service-review .line_agree_pp .custom_check').after('<div class="text-danger">' + json['error_agree_pp'] + '</div>');
				}

				if (json['success']) {
					$('#add_service_review_slide').removeClass('active');

					$('#form-service-review .reset_input').removeClass('error');
					$('#form-service-review .text-danger').remove();
					$('#form-service-review .input_container').removeClass('has_error');

					$('#form-service-review input[name=\'name\']').val('');
					$('#form-service-review input[name=\'email\']').val('');
					$('#form-service-review input[name=\'telephone\']').val('');
					$('#form-service-review textarea[name=\'message\']').val('');
					$('.reviewStars-input input').prop('checked', false);
					$('#form-service-review #service_review_agree_personal_data').prop('checked', false);
					$('#form-service-review #service_review_agree_privacy_policy').prop('checked', false);


					$('#modal_container_success .content .content_title').html(json['success_title']);
					$('#modal_container_success .content  .content_text').html(json['success']);

					setTimeout(function () {
						$('#modal_container_success').addClass('active');
					}, 600);
				}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log('error');
				//console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	});
}


/****************************/

function collapseSupportRecommendedArticles(){
	if ($('.support_more_recommended_articles_button').length) {
		$('.support_more_recommended_articles_button').click(function(){
			$(this).toggleClass('active');
			$(this).parents('.list-container').find('.list').toggleClass('active');
		});
	}
}

function openWriteTechnician(){
    if($('#write_technician_slide').length){
        $('.write_technician').click(function(){
			console.log('write_technician');
            $('body').addClass('overflow_hidden')
            $('.info_overlay').addClass('active');
            $('#write_technician_slide').addClass('active');
        })
    }
}

function sendTechnicianForm(){
	$('#form-technician #button-technician').click(function() {
		console.log('sendTechnicianForm');
		$.ajax({
			url: 'index.php?route=sendforms/send',
			type: 'post',
			data: $('#form-technician').serialize(),
			dataType: 'json',

			success: function(json) {
				$('#form-technician .input_container').removeClass('has_error');
				$('#form-technician input').removeClass('has_error');
				$('#form-technician textarea').removeClass('has_error');
				$('#form-technician .text-danger').remove();



				if (json['error_name']) {
					$('#form-technician #input-name-technician').parent().addClass('has_error');
					$('#form-technician #input-name-technician').addClass('has_error');
					$('#form-technician #input-name-technician').after('<div class="text-danger">' + json['error_name'] + '</div>');
					$('#form-technician #input-name-technician').focus();
				}
				if (json['error_email']) {
					$('#form-technician #input-email-technician').parent().addClass('has_error');
					$('#form-technician #input-email-technician').addClass('has_error');
					$('#form-technician #input-email-technician').after('<div class="text-danger">' + json['error_email'] + '</div>');
					$('#form-technician #input-email-technician').focus();
				}
				if (json['error_telephone']) {
					$('#form-technician #input-telephone-technician').parent().addClass('has_error');
					$('#form-technician #input-telephone-technician').addClass('has_error');
					$('#form-technician #input-telephone-technician').after('<div class="text-danger">' + json['error_telephone'] + '</div>');
					$('#form-technician #input-telephone-technician').focus();
				}
				if (json['error_city']) {
					$('#form-technician #input-city-technician').parent().addClass('has_error');
					$('#form-technician #input-city-technician').addClass('has_error');
					$('#form-technician #input-city-technician').after('<div class="text-danger">' + json['error_city'] + '</div>');
					$('#form-technician #input-city-technician').focus();
				}
				if (json['error_message']) {
					$('#form-technician #input-comment-technician').parent().addClass('has_error');
					$('#form-technician #input-comment-technician').addClass('has_error');
					$('#form-technician #input-comment-technician').after('<div class="text-danger">' + json['error_message'] + '</div>');
					$('#form-technician #input-comment-technician').focus();
				}
				if (json['error_agree_pd']) {
					$('#form-technician .line_agree_pd .custom_check').after('<div class="text-danger">' + json['error_agree_pd'] + '</div>');
				}
				if (json['error_agree_pp']) {
					$('#form-technician .line_agree_pp .custom_check').after('<div class="text-danger">' + json['error_agree_pp'] + '</div>');
				}
				if (json['success']) {
					$('#form-technician .reset_input').removeClass('error');
					$('#form-technician .text-danger').remove();
					$('#form-technician .input_container').removeClass('has_error');

					$('#form-technician input[name=\'name\']').val('');
					$('#form-technician input[name=\'serial_number\']').val('');
					$('#form-technician textarea[name=\'text\']').val('');
					$('#form-technician #feedback_agree_personal_data').prop('checked', false);
					$('#form-technician #feedback_agree_privacy_policy').prop('checked', false);


					$('#modal_container_success .content .content_title').html(json['success_title']);
					$('#modal_container_success .content  .content_text').html(json['success']);

					$('#write_technician_slide').removeClass('active');

					setTimeout(function () {
						$('#modal_container_success').addClass('active');
					}, 600);
				}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log('error');
				//console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	});
}

function supportSearch(){
	$('html').click(function() {
		$('.support_search .reset_input').val('');
		$('.search_ajax_container').empty();
	});
	$('.support_search').click(function(event){
		event.stopPropagation();
	})


	$(document).on("keyup", ".support_search input[name=\"search\"]", function(e) {
		setTimeout(function () {
			console.log('support_search_ajax');
			var search = $(".support_search input[name=\"search\"]").val();
			var blog_category_id = $(".support_search input[name=\"search\"]").data('blog-category-id');
			if (search.length > 2) {
				$.ajax({
					url: "index.php?route=blog/search/autocomplete",
					type: "post",
					data: "filter_name=" + search + '&blog_category_id=' + blog_category_id,
					dataType: "json",
					success: function success(json) {
						$(".ajax_search_results").remove();
						$(".search_ajax_container").append(json["html"]);
						$(".search_ajax_container .ajax_search_results").jScrollPane();
					},
					error: function error(xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
					}
				})
			} else {
				$(".ajax_search_results").remove();
			}
		}, 600);
	}); /* search */
}





function buyInPartners(){
	if($('.box_partners_slider .partners_slider').length){
		$('.box_partners_slider .partners_slider').slick( {
			dots: false,
			autoplay: true,
			infinite: true,
			arrows: true,
			prevArrow: '<span class="ani icon icon-arrow_left"></span>',
			nextArrow: '<span class="ani icon icon-arrow_right"></span>',
			speed: 300,
			slidesToShow: 4,
			slidesToScroll:1,
			appendArrows: $('.box_partners_slider .partners_slider').parents('.blog_partners_page').find('.box_heading .arrows_container'),

			responsive: [{
				breakpoint: 1366,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			}]
		} );
	}

}

function partnersMobileSlider(){
	if($('.box_partners_mobile_slider .partners_mobile_slider').length){
		if($(document).outerWidth() < 767){
			$('.box_partners_mobile_slider .partners_mobile_slider').slick( {
				autoplay: false,
				infinite: true,
				arrows: false,
				dots: true,
				prevArrow: '<span class="ani icon icon-arrow_left"></span>',
				nextArrow: '<span class="ani icon icon-arrow_right"></span>',
				speed: 300,
				slidesToShow: 2,
				slidesToScroll:1,
			} );
		}
	}
}


function findServiceCenter(){
	$(document).on("keyup", ".service_search input[name=\"search\"]", function(e) {
			//console.log('service_search_ajax');
			var search = $(".service_search input[name=\"search\"]").val();
			if (search.length > 2) {
				$.ajax({
					url: "index.php?route=services/services/autocomplete",
					type: "post",
					data: "filter_name=" + search,
					dataType: "json",
					success: function success(json) {
						//If we find service center
						if (json['service_centers'] != 'false') {
							//console.log('finded services');
							$(".service_search .ajax_search_results").remove();
							setTimeout(function () {
								reinitMap(json["service_centers"]);
								sessionStorage.setItem('services_map', 'filtered');
							}, 1000);
						} else {
							//console.log('NOT find services');
							sessionStorage.setItem('services_map', 'filtered');
							$(".service_search .ajax_search_results").remove();
							$(".service_search .search_ajax_container").append(json["html"]);
						}
					},
					error: function error(xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
					}
				})
			} else {
				if (sessionStorage.getItem('services_map') == 'filtered') {
					sessionStorage.removeItem('services_map');
					$(".service_search .ajax_search_results").remove();
					setTimeout(function () {
						initMap();
					}, 500);
				}
			}
	}); /* findServiceCenter */
}

function reinitMap($service_centers){

	var image = {
		url: 'image/pin.png',
		size: new google.maps.Size(27, 36),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(9, 32),
		scaledSize: new google.maps.Size(27, 36)
	}

	var markers = [];
	var markers = JSON.parse($service_centers);

	console.log(markers);

	//For centered map
	if(markers.length >= 1){
		var ServiceCenter = {lat: Number(markers[0]['geocode'].split(",")[0]), lng:  Number(markers[0]['geocode'].split(",")[1])};
		//console.log(Number(markers[0]['geocode'].split(",")[0]),Number(markers[0]['geocode'].split(",")[1]));
		//console.log(ServiceCenter);
	}

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 11,
		center: ServiceCenter,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP]
		}
	});

	var infoWindow = new google.maps.InfoWindow();

	var marker, i;

	for (i = 0; i < markers.length; i++) {
		marker = new google.maps.Marker({
			icon: image,
			position: new google.maps.LatLng(Number(markers[i]['geocode'].split(",")[0]),Number(markers[i]['geocode'].split(",")[1])),
			map: map
		});

		var latLng = markers[i]['geocode'];


		google.maps.event.addListener(marker, 'click', (function(marker, i) {
			return function() {
				var html = "<div class='desc'>"+markers[i]['name']+"</div><div class='desc'>"+markers[i]['description']+"</div>";
				infoWindow.setContent(html);
				infoWindow.open(map, marker);
			}
		})(marker, i));

	}
}

//Open CheckSerialPopup in in url have parametr "check_original_slide"
function getParametr(key) {
	var p = window.location.search;
	p = p.match(new RegExp(key + '=([^&=]+)'));
	return p ? p[1] : false;
}
function openCheckSerialPopup(){
	if ($('#check_original_slide').length){

		setTimeout(function () {
			let form = getParametr('form');

			if(form == 'check_original_slide'){

				$([document.documentElement, document.body]).animate({
					scrollTop: $("#anchor_box_help").offset().top - 50
				}, 1500, function() {
					$('body').addClass('overflow_hidden')
					$('.info_overlay').addClass('active');
					$('#check_original_slide').addClass('active');
				});

			}
		}, 1000);
	}

}

function collapseSearchDocumentsFilter(){
	if($('.documents_search_block .filters_content').length){
		$('.documents_search_block .button_collapse_filters').click(function(){
			$(this).toggleClass('active');
			$('.documents_search_block .filters_content').toggleClass('active');
		});
	}
}


function collapseCompareAttributes(){
	if($('.product-compare').length){
		$('.compare_mobile_attributes_block .attribute_group_title').click(function(){
			$(this).find('.collapseAttributeGroup').toggleClass('active');
			$(this).parents('.attribute_group_block').find('.attribute_group_compares').toggleClass('active');
		});
	}
}

function compareDifferencies(){

	if($('.product-compare').length) {
		//Десктопне порівняння
		$('.compare_attributes_block .line-attr').each(function () {
			let attr_values = [];
			$(this).find('.value-cell').each(function () {
				$value = $.trim($(this).text());
				attr_values.push($value);
			})
			//console.log(attr_values);
			//Якщо всі елементи массиву attr_values рівні
			if (attr_values.every( (val, i, arr) => val === arr[0])) {
				$(this).toggleClass('equaled');
			}
		});
		//Мобільне порівняння
		$('.compare_mobile_attributes_block .attribute_group_block .att_block_values').each(function () {
			let mob_attr_values = [];
			$(this).find('.attribute_value').each(function () {
				$value = $.trim($(this).text());
				mob_attr_values.push($value);
			})
			//console.log(mob_attr_values);
			//Якщо всі елементи массиву attr_values рівні
			if (mob_attr_values.every( (val, i, arr) => val === arr[0])) {
				$(this).parents('.att_block').toggleClass('equaled');
			}
		});



	}
}

function  initCompareDifferencies(){
	if($('.product-compare').length){
		$('#show_differencies').change(function(){
			//console.log('initCompareDifferencies');
			compareDifferencies();
		});
	}
}



function reinitPartnersMap($partners){

	var image = {
		url: 'image/pin.png',
		size: new google.maps.Size(27, 36),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(9, 32),
		scaledSize: new google.maps.Size(27, 36)
	}

	var markers = [];
	var markers = JSON.parse($service_centers);

	console.log(markers);

	//For centered map
	if(markers.length >= 1){
		var ServiceCenter = {lat: Number(markers[0]['geocode'].split(",")[0]), lng:  Number(markers[0]['geocode'].split(",")[1])};
		//console.log(Number(markers[0]['geocode'].split(",")[0]),Number(markers[0]['geocode'].split(",")[1]));
		//console.log(ServiceCenter);
	}

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 11,
		center: ServiceCenter,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP]
		}
	});

	var infoWindow = new google.maps.InfoWindow();

	var marker, i;

	for (i = 0; i < markers.length; i++) {
		marker = new google.maps.Marker({
			icon: image,
			position: new google.maps.LatLng(Number(markers[i]['geocode'].split(",")[0]),Number(markers[i]['geocode'].split(",")[1])),
			map: map
		});

		var latLng = markers[i]['geocode'];


		google.maps.event.addListener(marker, 'click', (function(marker, i) {
			return function() {
				var html = "<div class='desc'>{{ text_hotline_desc }}</div><a class='tel' href='tel:{{ holine_telephone_number }}'>{{ holine_telephone }}</a></div>";
				infoWindow.setContent(html);
				infoWindow.open(map, marker);
			}
		})(marker, i));

	}
}



function focusedInputs(){
	$('.input_container > input').focus(function() {
		$(this).parents('.input_container').addClass('focused')
	});
	$('.input_container > input').blur(function(){ // задаем функцию при потери фокуса элементом <input>
		$(this).parents('.input_container').removeClass('focused')
	});
	//Textarea
	$('.input_container > textarea').focus(function() {
		$(this).parents('.input_container').addClass('focused')
	});
	$('.input_container > textarea').blur(function(){ // задаем функцию при потери фокуса элементом <input>
		$(this).parents('.input_container').removeClass('focused')
	});
}

function documentationSearch(){
	if($('.documents_search').length) {
		$(document).on("keyup", ".documents_search input[name=\"search\"]", function (e) {
			setTimeout(function () {
				console.log('documents_search_ajax');
				var search = $(".documents_search input[name=\"search\"]").val();

				if (search.length > 2) {
					$.ajax({
						url: "index.php?route=blog/search_documentation_ajax/autocomplete",
						type: "post",
						data: "filter_name=" + search,
						dataType: "json",
						success: function success(json) {
							$('.find_documents_results_block_content>.title span').text(json["total"]);
							$('.find_documents_results_block_content>.sub_title span').text(search);
							$(".ajax_search_results").remove();
							$(".results_block_ajax").empty();
							$(".results_block_ajax").append(json["html"]);
							$(".find_documents_results_block").addClass('active');
						},
						error: function error(xhr, ajaxOptions, thrownError) {
							alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
						}
					})
				} else {
					$(".find_documents_results_block").removeClass('active');
					$(".ajax_search_results").remove();
				}
			}, 600);
		}); /* search */
	}

}

function documentationSearchByClick(){
	console.log('documentationSearchByClick');
	if ($('body').hasClass('blog-search_documentation')) {
		$('#documents_search').click(function(){
			var url = $('base').attr('href') + 'index.php?route=blog/search_documentation';
			var value = $(this).parents('.documents_search').find('input[name=\'search\']').val();
			if (value) {
				url += '&search=' + encodeURIComponent(value);
			}
			location = url;
		});
		$('.documents_search input[name=\'search\']').on('keydown', function (e) {
			console.log('keydown');
			if (e.keyCode == 13) {
				$('#documents_search').trigger('click');
			}
		});
	}
}


function documentationFilterSearch(){

	if($('.documents_search_block .filters_block').length) {
		//Фільтрація товарів по категорії
		$(document).on("change", ".documents_search_block .filters_block select[name=\"category_id\"]", function (e) {
			console.log('documentationFilterSearch change')
			setTimeout(function () {
				console.log('documentationFilterSearch STEP 1');
				var category_id = $(".documents_search_block .filters_block select[name=\"category_id\"]").val();
					$.ajax({
						url: "index.php?route=blog/search_documentation_filter/category",
						type: "post",
						data: "category_id=" + category_id,
						dataType: "json",
						success: function success(json) {
							//json["total"]
							if(json['success']){
								$('.documents_search_block .filters_content .model-select').empty();
								$('.documents_search_block .filters_content .model-select').append(json["html"]);

								customSelectInit();

								$('.filters_content .line-1 .line_content .col-2 .custom-select').removeClass('no-active');
							}
						},
						error: function error(xhr, ajaxOptions, thrownError) {
							alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
						}
					});
			}, 600);
		}); /* search */

		//Фільтрация товарів по моделі
		$(document).on("change", ".documents_search_block .filters_block select[name=\"model\"]", function (e) {
			setTimeout(function () {
				console.log('documentationFilterSearch STEP 2');
				var category_id = $(".documents_search_block .filters_block select[name=\"category_id\"]").val();
				var model = $(".documents_search_block .filters_block select[name=\"model\"]").val();
				$.ajax({
					url: "index.php?route=blog/search_documentation_filter/model",
					type: "post",
					data: "category_id=" + category_id + '&model=' + model,
					dataType: "json",
					success: function success(json) {
						//json["total"]
						if(json['success']){
							$('.documents_search_block .filters_content .seria-select').empty();
							$('.documents_search_block .filters_content .seria-select').append(json["html"]);
							customSelectInit();
							$('.filters_content .line-2 .line_content .col-3 .custom-select').removeClass('no-active');
						}
					},
					error: function error(xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
					}
				});
			}, 600);
		}); /* search */


		//Фільтрація товарів по серії
		$(document).on("change", ".documents_search_block .filters_block select[name=\"seria\"]", function (e) {
			setTimeout(function () {
				console.log('documentationFilterSearch STEP 3');
				var category_id = $(".documents_search_block .filters_block select[name=\"category_id\"]").val();
				var seria = $(".documents_search_block .filters_block select[name=\"seria\"]").val();
				$.ajax({
					url: "index.php?route=blog/search_documentation_filter/seria",
					type: "post",
					data: "category_id=" + category_id + '&seria=' + seria,
					dataType: "json",
					success: function success(json) {
						//json["total"]
						if(json['success']){
							$('#search_documents').removeClass('no-active');
						}
					},
					error: function error(xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
					}
				});
			}, 600);
		}); /* search */



		//Фінальна фільтрація
		$(document).on("click", ".documents_search_block .filters_content #search_documents", function (e) {
			setTimeout(function () {
				var category_id = $(".documents_search_block .filters_block select[name=\"category_id\"]").val();
				var seria = $(".documents_search_block .filters_block select[name=\"seria\"]").val();
				$.ajax({
					url: "index.php?route=blog/search_documentation_filter/seria",
					type: "post",
					data: "category_id=" + category_id + '&seria=' + seria,
					dataType: "json",
					success: function success(json) {
						//json["total"]
						if(json['success']){
							$('.find_documents_results_block_content>.title span').text(json["total"]);
							$('.find_documents_results_block_content>.sub_title span').text(seria);
							$(".ajax_search_results").remove();
							$(".results_block_ajax").empty();
							$(".results_block_ajax").append(json["html"]);
							$(".find_documents_results_block").addClass('active');

							$('#reset_documents_filter').removeClass('no-active');
						}
					},
					error: function error(xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText)
					}
				});
			}, 600);
		}); /* search */

		//Очищення фільтра

		$(document).on("click", "#reset_documents_filter", function (e) {
			/*$(".documents_search_block .filters_content select").each(function(){
				/*$def = $('option:first', this).val();
				$(this).val($def);
				$(this).removeClass('is-customized');
				$select = $(this);*/

				//customSelectInit();
				/*$select_container = $(this).parents('.custom-select');
				$select_container.empty();
				setTimeout(function () {
					console.log('insert');
					$select_container.append($select);
					customSelectInit();
				}, 1000);

				var sb = new SelectBox({
					selectbox: $(this),
				});
			});

			$('.find_documents_results_block').removeClass('active');
			$('.find_documents_results_block_content>.title span').text('');
			$('.find_documents_results_block_content>.sub_title span').text('');
			$(".ajax_search_results").remove();
			$(".results_block_ajax").empty();
			*/
			loacation();


		});

	}
}


$(document).ready(function() {
	scrollToTop();
	
	focusedInputs();
	onlyDigit();
	fixTopLine();
	mobileHeaderMenu();
	mainCatalogMenu();
	subMenuHeight();

	// openMobileSearch();


	setTimeout(function () {
		//fixTopLine();
		$('input[type=\'tel\']').mask("+38 (099) 999 99 99");
		customSelectInit();
		equalHeightCategoriesMobile();
	}, 600);


	mainSlideshow();
	blogSlider();
	collapseAboutCompany();
	openSearch();

	sendNewsLetterForm();

	mobileFooterMenu();

	addViewFilterMegaFilter();
	productsGrid();
	productsSlider();
	collapseCatDesc();

	openCloseFilter();
	resetFilter();

	productPageSlider();
	collapseProductDesc();
	closeInfoSlides();

	showAllCharacterictics();
	buttonAnchor();
	productCardMenuAnchors();
	productCardToReviews();
	collapseProductReview();
	collapseProductInfoBlocks();
	collapseProductAttributes();

	productPartnersSlider();
	mobilePartnersSlider();

	//Blog
	//filterBlogArticles();
	//fixBlogInfoMenu();

	openFeedbackForm();
	sendFeedbackForm();
	openCheckSerialNumber();
	openProductRegister();
	sendRegisterProductForm();

	collapseSupportRecommendedArticles();
	openWriteTechnician();
	sendTechnicianForm();

	supportSearch();
	openServiceReviewForm();
	sendServiceReviewForm();

	//Buy in partners page slider
	buyInPartners();


	findServiceCenter();

	//Open CheckSerialPopup in in url have parametr "check_original_slide"
	openCheckSerialPopup();

	collapseSearchDocumentsFilter();

	collapseCompareAttributes();
	initCompareDifferencies();

	partnersMobileSlider();


	documentationSearch();
	documentationSearchByClick();
	documentationFilterSearch();






	/**********************************/
	// Language
	$('.form-language .language-select').on('click', function(e) {
		e.preventDefault();
		$('.form-language input[name=\'code\']').val($(this).attr('name'));
		$('.form-language').submit();
	});


	/* Search */
	$('.search input[name=\'search\']').parents('.search').find('button.search-button').on('click', function() {
		var url = $('base').attr('href') + 'index.php?route=product/search';
		var value = $(this).parents('.search').find('input[name=\'search\']').val();
		if (value) {
			url += '&search=' + encodeURIComponent(value);
		}
		location = url;
	});
	$('.search input[name=\'search\']').on('keydown', function(e) {
		console.log('keydown');
		if (e.keyCode == 13) {
			//$('.search input[name=\'search\']').closest('.search').find('button').trigger('click');
			$(this).parents('.search').find('button').trigger('click');
		}
	});




});
// $(window).on('resize', function(e){
// 	fixTopLine();
// });

var cart = {
	'add': function(product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			beforeSend: function() {
				//$('.cart > button').button('loading');
			},
			complete: function() {
				//$('.cart > button').button('reset');
			},
			success: function(json) {
				$('.alert-dismissible, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {

					setTimeout(function () {

						$("#modal_container_addcart #cart_popup_product_list > ul").load("index.php?route=common/cart/info ul li", function () {

							$('#modal_container_addcart .modal-header .title span').text('(' + json['total'] + ')');

							setTimeout(function () {
								onlyDigit()
							}, 100);
							$('#modal_container_addcart').modal();
						});


						let product_name = $('#product .product__top_block h1.name a').text();
						$('#modal_cart .info').text(product_name);

						$('.cart_button .cart-total' ).html(json['total']);
						$('.cart_button .cart-total').addClass('active');


						$('#modal_cart').addClass('active');

						$('#modal_cart .heading button').click(function(){
							$('#modal_cart').removeClass('active');
						});
						setTimeout(function () {
							$('#modal_cart').removeClass('active');
						}, 4000);


					}, 100);

				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},

	'update': function(key, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/editAjax',
			type: 'post',
			//data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			//data: 'quantity[' + key + ']=' + quantity,
			data: 'quantity[' + key + ']=' + quantity + '&product_id=' + product_id,
			dataType: 'json',
			beforeSend: function() {
				//$('.cart > button').button('loading');
			},
			complete: function() {
				//$('.cart > button').button('reset');
			},
			success: function(json) {

				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('#modal_container_addcart .modal-header .title span' ).html('('+json['total']+')');
					$('.cart_button .cart-total' ).html(json['total']);
					$('.cart_button .cart-total').addClass('active');

					// Change product subtotal in line
					$('#cart_popup_product_list .qty_total_col .total-'+ key).html(json['changed_product_total']);
					$('#modal_container_addcart .totals_checkout_block .total-total .value').html(json['total_sum']);
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/removeAjax',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('.cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},
			success: function(json) {

				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {

					if(json['total'] == 0){
						$('.cart_button .cart-total').removeClass('active');
						$('#modal_container_addcart').modal('hide');
					}

					$('#modal_container_addcart .modal-header .title span' ).html('('+json['total']+')');
					$('.cart_button .cart-total' ).html(json['total']);
					$('#cart_popup_product_list .line-'+ key).remove();

					// Change product subtotal in line
					$('#cart_popup_product_list .qty_total_col .total-'+ key).html(json['changed_product_total']);
					$('#modal_container_addcart .totals_checkout_block .total-total .value').html(json['total_sum']);
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}

var voucher = {
	'add': function() {

	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('.cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('.cart > button').html('<span class="cart-total">' + json['total'] + '</span>');
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}



var wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=account/wishlist/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {

				$('.alert-dismissible').remove();
				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {


					var list, index;
					list = document.getElementsByClassName('wishlist-' + product_id);
					for (index = 0; index < list.length; ++index) {
						list[index].setAttribute('disabled', 'disabled');
						list[index].title = json['in_wishlist'];
						var product = list[index];

						$('.modal_wishlist .info').text(json['product_name']);
						$('.wishlist-button .wishlist-total' ).html(json['total']);
						$('.wishlist-button .wishlist-total').addClass('active')
					}

					$('.modal_wishlist').addClass('active');

					setTimeout(function () {
						$('.modal_wishlist').removeClass('active');
					}, 4000);
				}
				//$('html, body').animate({ scrollTop: 0 }, 'slow');
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}




function removeFromCompare($product_id){
	$.ajax({
		url: 'index.php?route=product/compare/remove',
		type: 'post',
		data: 'product_id=' + $product_id,
		dataType: 'json',
		success: function(json) {
			if (json['success']) {
				console.log('remove_success');

				var list_popup_products, index;
				list_popup_products = document.getElementsByClassName('product-compare-' + $product_id);
				for (index = 0; index < list_popup_products.length; ++index) {
					list_popup_products[index].remove();
				}

				var list_products, index2;
				list_products = document.getElementsByClassName('compare-' + $product_id);
				for (index2 = 0; index2 < list_products.length; ++index2) {
					list_products[index2].removeAttribute('disabled', 'disabled');
					list_products[index2].classList.remove('active');
				}


				if(json['total'] == 0){
					$('.compare-total-counter' ).removeClass('active');
					$('#add_product_compare').addClass('empty');
				}

				$('.compare-total-counter' ).html(json['total']);
			}
		},
		error: function(xhr, ajaxOptions, thrownError) {
			alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
		}
	});
}


function removeAllFromCompare(){
	$.ajax({
		url: 'index.php?route=product/compare/removeAll',
		type: 'post',
		data: '',
		dataType: 'json',
		success: function(json) {
			if (json['success']) {
				console.log('remove_All_success');

				$('#add_product_compare .compare_products_list .product-compare-inpopup').remove();
				$('.product .compare_button').removeClass('active');
				$('.product .compare_button').prop("disabled", false);

				$('.compare-total-counter' ).html('');
				$('.compare-total-counter' ).removeClass('active');
				$('#add_product_compare').addClass('empty');

			}
		},
		error: function(xhr, ajaxOptions, thrownError) {
			alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
		}
	});
}


var compare = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=product/compare/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {

				$('.alert-dismissible').remove();
				if (json['success']) {
					console.log('compare_success');
					//$('#content').parent().before('<div class="alert alert-success alert-dismissible"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');

					$('#add_product_compare').removeClass('empty');
					var list, index;
					list = document.getElementsByClassName('compare-' + product_id);
					for (index = 0; index < list.length; ++index) {
						list[index].setAttribute('disabled', 'disabled');
						list[index].classList.add('active');
						var product = list[index];
						var product_name = $(product).parents('.product').find('.name a').html();

						setTimeout(function () {
							console.log('getComparedProducts');
							$("#add_product_compare .content").load("index.php?route==product/compare/info", function () {
								$('body').addClass('overflow_hidden');
								$('.info_overlay').addClass('active');
								$('#add_product_compare').addClass('active');
							});
						}, 200);


						$('.compare-total-counter' ).html(json['total']);
						$('.compare-total-counter').addClass('active')
					}

				}else{
					console.log('error');

					if($('body').hasClass('product-page')){
						$('html, body').animate({scrollTop: 0}, 'fast');
					}

					$('.compare-total-container #compare-error .text').html(json['error']);
					$('.compare-total-container #compare-error').addClass('active');
					setTimeout(function () {
						$('.compare-total-container #compare-error').removeClass('active');
					}, 4000);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);

			}
		});
	},
	'remove': function() {

	}
}

/* Agree to Terms */
$(document).delegate('.agree', 'click', function(e) {
	e.preventDefault();

	$('#modal-agree').remove();

	var element = this;

	$.ajax({
		url: $(element).attr('href'),
		type: 'get',
		dataType: 'html',
		success: function(data) {
			html  = '<div id="modal-agree" class="modal">';
			html += '  <div class="modal-dialog">';
			html += '    <div class="modal-content">';
			html += '      <div class="modal-header">';
			html += '        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>';
			html += '        <h4 class="modal-title">' + $(element).text() + '</h4>';
			html += '      </div>';
			html += '      <div class="modal-body">' + data + '</div>';
			html += '    </div>';
			html += '  </div>';
			html += '</div>';

			$('body').append(html);

			$('#modal-agree').modal('show');
		}
	});
});

// Autocomplete */
(function($) {
	$.fn.autocomplete = function(option) {
		return this.each(function() {
			this.timer = null;
			this.items = new Array();

			$.extend(this, option);

			$(this).attr('autocomplete', 'off');

			// Focus
			$(this).on('focus', function() {
				this.request();
			});

			// Blur
			$(this).on('blur', function() {
				setTimeout(function(object) {
					object.hide();
				}, 200, this);
			});

			// Keydown
			$(this).on('keydown', function(event) {
				switch(event.keyCode) {
					case 27: // escape
						this.hide();
						break;
					default:
						this.request();
						break;
				}
			});

			// Click
			this.click = function(event) {
				event.preventDefault();

				value = $(event.target).parent().attr('data-value');

				if (value && this.items[value]) {
					this.select(this.items[value]);
				}
			}

			// Show
			this.show = function() {
				var pos = $(this).position();

				$(this).siblings('ul.dropdown-menu').css({
					top: pos.top + $(this).outerHeight(),
					left: pos.left
				});

				$(this).siblings('ul.dropdown-menu').show();
			}

			// Hide
			this.hide = function() {
				$(this).siblings('ul.dropdown-menu').hide();
			}

			// Request
			this.request = function() {
				clearTimeout(this.timer);

				this.timer = setTimeout(function(object) {
					object.source($(object).val(), $.proxy(object.response, object));
				}, 200, this);
			}

			// Response
			this.response = function(json) {
				html = '';

				if (json.length) {
					for (i = 0; i < json.length; i++) {
						this.items[json[i]['value']] = json[i];
					}

					for (i = 0; i < json.length; i++) {
						if (!json[i]['category']) {
							html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
						}
					}

					// Get all the ones with a categories
					var category = new Array();

					for (i = 0; i < json.length; i++) {
						if (json[i]['category']) {
							if (!category[json[i]['category']]) {
								category[json[i]['category']] = new Array();
								category[json[i]['category']]['name'] = json[i]['category'];
								category[json[i]['category']]['item'] = new Array();
							}

							category[json[i]['category']]['item'].push(json[i]);
						}
					}

					for (i in category) {
						html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';

						for (j = 0; j < category[i]['item'].length; j++) {
							html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
						}
					}
				}

				if (html) {
					this.show();
				} else {
					this.hide();
				}

				$(this).siblings('ul.dropdown-menu').html(html);
			}

			$(this).after('<ul class="dropdown-menu"></ul>');
			$(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));

		});
	}
})(window.jQuery);
