function showmorereviews() {

    var $next = $('.product-page #block-reviews .pagination_container .pagination li:last-child a.has_link');

    if ($next.length == 0) {
        return;
    }
    $.get($next.attr('href'), function (data) {
        $data = $(data);

        var $container = $('.product-page #block-reviews #review-list-container #review-list');
		$container.append($data.find('.review_item'));

        $('.product-page #review-list-container .bottom-wrapper').empty();
        var $container_pagination = $('.product-page #block-reviews #review-list-container .bottom-wrapper');
        $container_pagination.append($data.find('.pagination_container'));

        if ($('.product-page #block-reviews .pagination li.active').next('li').find('a.has_link').length == 0) {
            $('#showmorereviews').hide();
        }

    }, "html");
    //return false;
}
