function showmoresupport() {
    console.log('showmoresupport');

    var $next = $('.box_all_recomended_articles .pagination_container .pagination li:last-child a.has_link');

    if ($next.length == 0) {
        return;
    }
    $.get($next.attr('href'), function (data) {
        $data = $(data);

        var $container = $('.box_all_recomended_articles .list-container .list');

        $container.append($data.find('.list .support_article'));

        $('.box_all_recomended_articles .bottom-wrapper').empty();
        var $container_pagination = $('.box_all_recomended_articles .bottom-wrapper');
        $container_pagination.append($data.find('.box_all_recomended_articles .bottom-wrapper .pagination_container'));

        if ($('.box_all_recomended_articles .pagination_container .pagination li.active').next('li').find('a.has_link').length == 0) {
            $('#showmoresupport').hide();
        }
    }, "html");
    //return false;
}
